<template>
    <div>
        <FilterView
            v-if="view_type === 'filter'"
            ref="FilterView"
            @change-path="change_path"
            @get-result="get_result" />

        <GridView
            v-if="view_type === 'grid'"
            ref="GridView" />

        <ChartView
            v-if="view_type === 'chart'"
            ref="ChartView" />
    </div>
</template>

<script>
var encrypter = require('object-encrypter');
var enc_engine = encrypter('orkl', {ttl: false});

import {firstBy} from "thenby";

import FilterView from './FilterView'
import GridView from './GridView'
import ChartView from './ChartView'

export default {
    components: {
        FilterView,
        GridView,
        ChartView
    },

    props: {
        p: {
            required: false,
        },
    },

    data(){
        return {
            view_type  : undefined,
        }
    },

    methods: {
        change_path(data){
            // data.view_type = this.view_type;

            // let hex = enc_engine.encrypt(data);
            // if (this.$router.currentRoute.name === 'search_isin')
            //     history.pushState(
            //         {},
            //         null,
            //         '#' + this.$route.path + '?p=' + encodeURIComponent(hex)
            //     );
            // this.$emit('tab-query-change', {k: 'p', v: encodeURIComponent(hex)})
        },

        get_result(data) {
            delete data.filter_id;
            let hex = enc_engine.encrypt(data);
            //console.log(encodeURIComponent(hex))
            this.$router.push('/searchisin/' + encodeURIComponent(hex))
        }
    },

    mounted(){
        if (this.p) {
            this.$emit('tab-title-change', 'Search ISIN Results')
            let q = decodeURIComponent(this.p)
            let data = enc_engine.decrypt(q)
            this.view_type = data.view_type;

            this.$nextTick(() => {
                if(this.view_type === 'grid'){
                    this.$refs.GridView.load_data(data);
                }
                if(this.view_type === 'chart'){
                    this.$refs.ChartView.init_chart(data);
                }
            })
        }

        else {
            this.$emit('tab-title-change', 'Search ISIN')
            this.view_type = 'filter';
            this.$nextTick(() => {
                this.$refs.FilterView.load_init_data();
            })
        }
    }
}
</script>
