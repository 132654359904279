<template>
    <div class="si-grid-view-page" v-loading="loading" style="width:100%">
        <div class="prb-result-buttons">
            <div class="spacer" />
            <el-button size="medium" @click="export_to_excel">
                export to excel
            </el-button>
        </div>
        <webix-ui :config='report_grid' v-model='report_data' ref="webixTbl" v-if="report_grid" />
    </div>
</template>

<script>
import * as webix from 'webix'
import '@/directives/webix/'
import $ from 'jquery'
import moment from 'moment'

export default {
    data() {
        return {
            loading      : false,
            asset        : undefined,
            date         : undefined,
            fields       : [],
            columns_list : [],
            report_data  : [],
            report_grid  : undefined,
            grid_id      :  "searchIsinGrid" + Date.now()
        }
    },

    methods: {
        load_data(data){
            this.asset = data.asset
            this.date = data.date
            this.columns_list = data.columns || []
            // this.filters = data.filters
            this.loading = true
            this.$store.dispatch('searchIsin/searchIsinFields', this.asset).then((fields_response) => {
                this.fields = fields_response;

                let filters = data.filters.filter(f => { return f.apply })
                filters.forEach(f => {
                    delete f.has_errors;
                    delete f.count_result;

                    let field = this.fields.find(fld => fld.orkl_name === f.field)
                    if (['number','float'].includes(field.field_type) && !["IS EMPTY", "IS NOT EMPTY", "IN RANGE"].includes(f.logics)){
                        f.value *= 1
                    }
                    else if (['number','float'].includes(field.field_type) && ["IN RANGE"].includes(f.logics)){
                        f.value_list.forEach(vl => {
                            vl *= 1
                        })
                    }
                })

                let params = {
                    date    : this.date,
                    asset   : this.asset,
                    filters : filters,
                }

                this.$store.dispatch('searchIsin/searchIsinResult', params).then((response) => {
                    this.loading = false
                    this.report_data = response
                    this.get_table_config();
                })
            })
        },

        get_table_config(){
            let $this = this;
            let columns = $this.get_grid_columns()

            let table = {
                view         : "datatable",
                select       : "row",
                resizeColumn : true,
                columns      : columns,
                id           : $this.grid_id,
                scroll       : true,
                css          : "tl-wbx",
                on: {
                    onAfterLoad: function(){
                        let height = $(window).height() - 180
                        this.define('height', height);
                        this.resize();
                    }
                }
            }

            $this.report_grid = table
        },

        get_grid_columns(){
            let columns = []
            this.columns_list.forEach((column, index) => {
                let field_data = this.fields.find(x => x.orkl_name === column)
                if (!field_data)
                    return;

                let col = {
                    id     : column,
                    header : [{text:"<div>"+field_data.app_name+"</div>", css:"ta_c multiline", height:60}],
                    colname: field_data.app_name,
                    adjust : "data"
                }

                if (index == 0) {
                    col.fillspace = 1;
                    col.minWidth = 150;
                }

                if(col.id === 'maturity')
                    col.format = (value) => moment(value.$date).format('DD MMM YY')

                columns.push(col);
            })

            return columns
        },

        export_to_excel(){
            let filename = 'Search ISIN'
            let columns = []
            let tableColumns = this.get_grid_columns()

            tableColumns.forEach(tc => {
                let col = {
                    id     : tc.id,
                    header : tc.colname,
                    template:function(obj){
                        let val = obj[tc.id]
                        if (!val) {
                            return ''
                        }
                        else {
                            return val
                        }
                    },
                }

                columns.push(col)
            })

            webix.toExcel($$(this.grid_id), {
                filterHTML : true,
                filename   : filename,
                columns    : columns
            });
        }
    }
}
</script>
